import React from 'react';

// Components
import MainLayout from '../../components/main-layout';

const WorkWithUs = (props) => {
    const {pageContext} = props;

    return (
        <MainLayout pageContext={pageContext}></MainLayout>
    )
}

export default WorkWithUs;